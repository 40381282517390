@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .text-type-base {
    @apply text-gray-300 text-lg text-center;
  }

  .text-type-title {
    @apply text-gray-400 text-3xl text-center px-8;
  }

  .text-type-work {
    @apply font-montagu text-gray-200 text-2xl text-center;
  }

  .section-base {
    @apply flex flex-col gap-12 justify-center items-center py-80 w-screen;
  }
}

body {
  scroll-behavior: smooth;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(156 163 175);
  background-color: rgb(23 23 23);
  overflow-x: hidden;
}

::selection {
  background-color: #fff !important;
  color: #000 !important;
}

/* scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(156 163 175) rgb(23 23 23);
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgb(23 23 23);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(156 163 175);
  border-radius: 20px;
  border: 3px solid rgb(23 23 23);
}

/* 3D model */
canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
